<template>
  <div class="container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="留言管理" name="first">
        <el-form
          :inline="true"
          :model="formInline"
          ref="formInline"
          label-position="right"
          class="demo-form-inline"
          label-width="90px"
        >
          <el-form-item label="留言状态：" prop="messageStatus">
            <el-select
              v-model="formInline.messageStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in messageStatusList"
                :key="item"
                :label="
                  item == 0 ? '申请发布' : item == 2 ? '已发布' : '申请删除'
                "
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="button_left">
            <el-button type="primary" @click="onSubmit(1)" icon="el-icon-search"
              >查询</el-button
            >
            <el-button
              class="button_reset"
              @click="resetForm('formInline')"
              plain
              icon="el-icon-refresh"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item class="button_right">
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              cancel-button-type="Primary"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗?"
              @confirm="handleDeleteAll"
            >
              <el-button slot="reference" type="danger" icon="el-icon-delete">
                删除
              </el-button>
            </el-popconfirm>
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              cancel-button-type="Primary"
              icon="el-icon-info"
              icon-color="red"
              title="确定取消删除申请吗?"
              @confirm="handleRecoverAccountAll"
            >
              <el-button slot="reference" icon="el-icon-refresh-left" plain>
                取消删除申请
              </el-button>
            </el-popconfirm>
            <!-- <el-button
              @click="
                drawer = true;
                drawerTitle = '添加账号';
              "
              type="primary"
              icon="el-icon-circle-plus-outline"
            >
              添加账号
            </el-button> -->
          </el-form-item>
        </el-form>

        <el-table
          ref="multipleTable"
          :data="messageList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          stripe
          :height="height"
          size="mini"
        >
          <el-table-column type="selection" width="55" fixed> </el-table-column>
          <el-table-column type="index" width="60" label="序号">
          </el-table-column>
          <el-table-column prop="user_name" label="留言用户" min-width="100">
          </el-table-column>
          <el-table-column prop="gender" label="留言状态" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.message_status == 0">申请发布</span>
              <span v-else-if="scope.row.message_status == 1">申请删除</span>
              <span v-else>已发布</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="message_content"
            label="留言内容"
            min-width="500"
          >
          </el-table-column>

          <el-table-column
            prop="message_create_time"
            label="留言创建时间"
            :width="tabelColWidth"
          >
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="300">
            <template slot-scope="scope">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定发布吗？"
                v-show="
                  scope.row.message_status == 0 &&
                  ((scope.row.user_id != 1 && myUserInfo.user_id != 1) ||
                    myUserInfo.user_id == 1)
                "
                @confirm="handlePublish(scope.$index, scope.row)"
              >
                <el-button slot="reference" type="text" class="red">
                  发布
                </el-button>
              </el-popconfirm>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定取消发布吗？"
                v-show="
                  scope.row.message_status == 2 &&
                  ((scope.row.user_id != 1 && myUserInfo.user_id != 1) ||
                    myUserInfo.user_id == 1)
                "
                @confirm="handlePublish(scope.$index, scope.row)"
              >
                <el-button slot="reference" type="text"> 取消发布 </el-button>
              </el-popconfirm>

              <el-button
                type="text"
                @click="
                  handleEdit(scope.$index, scope.row);
                  drawerTitle = '编辑';
                "
                v-show="
                  (scope.row.user_id != 1 && myUserInfo.user_id != 1) ||
                  myUserInfo.user_id == 1
                "
              >
                编辑
              </el-button>

              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定删除吗？"
                v-show="
                  (scope.row.user_id != 1 && myUserInfo.user_id != 1) ||
                  myUserInfo.user_id == 1
                "
                @confirm="handleDelete(scope.$index, scope.row)"
              >
                <el-button type="text" slot="reference" class="red">
                  删除
                </el-button>
              </el-popconfirm>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定取消删除申请吗?"
                v-show="
                  scope.row.message_status == 1 &&
                  ((scope.row.user_id != 1 && myUserInfo.user_id != 1) ||
                    myUserInfo.user_id == 1)
                "
                @confirm="handleRecover(scope.$index, scope.row)"
              >
                <el-button type="text" slot="reference">
                  取消删除申请
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next"
          :total="messageTotal"
        >
        </el-pagination>

        <el-drawer
          :title="drawerTitle"
          :visible.sync="drawer"
          :with-header="true"
          :before-close="handleClose"
          size="39%"
          :destroy-on-close="true"
        >
          <!-- <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            v-show="drawerTitle == '添加账号'"
          >
            <el-form-item label="用户名" prop="username">
              <el-input
                v-model="ruleForm.username"
                placeholder="请填写用户名"
              ></el-input>
            </el-form-item>
            <el-form-item v-show="!disabled" label="密码" prop="password">
              <el-input
                v-model="ruleForm.password"
                placeholder="请填写密码"
                show-password
                :disabled="disabled"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-show="!disabled"
              label="确认密码"
              prop="checkPassword"
            >
              <el-input
                v-model="ruleForm.checkPassword"
                placeholder="请填写确认密码"
                show-password
                :disabled="disabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input placeholder="请填写邮箱" v-model="ruleForm.email" />
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model="ruleForm.code" placeholder="请填写验证码">
                <el-button
                  class="button_red"
                  slot="append"
                  @click="getCode(ruleForm.email)"
                  >获取验证码</el-button
                >
              </el-input>
            </el-form-item>
            <el-form-item class="right">
              <el-button @click="cancel" plain>取消</el-button>
              <el-button type="primary" @click="confirm('ruleForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form> -->
          <el-form
            :model="ruleForm2"
            :rules="rules"
            ref="ruleForm2"
            label-width="120px"
            v-show="drawerTitle == '编辑'"
          >
            <el-form-item label="留言内容" prop="messageContent">
              <el-input
                type="textarea"
                v-model="ruleForm2.messageContent"
              ></el-input>
            </el-form-item>
            <el-form-item class="right">
              <el-button @click="cancel" plain>取消</el-button>
              <el-button type="primary" @click="confirm('ruleForm2')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </el-drawer>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "MessagesManage",
  data() {
    return {
      disabled: false,
      height: 740,
      tabelColWidth: 230,
      currentPage: 1,
      drawerTitle: "",
      drawer: false,
      activeName: "first",
      messageIds: [],
      messageStatusList: [0, 1, 2],
      multipleSelection: [],
      formInline: {
        currentPage: 1,
        pageSize: 10,
        messageStatus: null,
      },
      ruleForm2: {
        messageId: null,
        messageContent: null,
      },
      rules: {
        messageContent: [
          { required: true, message: "请输入内容", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["myUserInfo"]),
    ...mapState("message", [
      "messageList",
      "status",
      "message",
      "messageTotal",
    ]),
  },
  methods: {
    ...mapActions("message", {
      getMessageB: "getMessageB",
      updateMessage: "updateMessage",
      deleteMessage: "deleteMessage",
      passMessage: "passMessage",
    }),
    // 更改状态
    handlePublish(index, row) {
      let ids = [];
      ids.push(row.message_id);
      this.messageIds = ids;
      let messageStatus = row.message_status == 0 ? 2 : 0;
      this.passMessage({
        messageIds: this.messageIds,
        messageStatus,
      }).then(() => {
        if (this.status == 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.message,
          });
          this.onSubmit();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.message,
          });
        }
      });
    },
    cancel() {
      this.handleClose();
    },
    // getContent(content) {
    //   this.ruleForm.content = content;
    // },
    handleSizeChange(val) {
      this.formInline.pageSize = val;
      // console.log(`每页 ${val} 条`);
      this.onSubmit();
    },
    handleCurrentChange(val) {
      this.formInline.currentPage = val;
      // console.log(`当前页: ${val}`);
      this.onSubmit();
    },
    // handleClick(tab, event) {
    //   console.log(tab, event);
    // },
    // 查询列表
    onSubmit(nowPage) {
      let currentPage;
      if (nowPage) {
        this.currentPage = nowPage;
        currentPage = nowPage;
        this.formInline.currentPage = nowPage;
      }
      currentPage = this.formInline.currentPage;
      let messageStatus =
        this.formInline.messageStatus || this.formInline.messageStatus == 0
          ? this.formInline.messageStatus
          : -1;
      let pageSize = this.formInline.pageSize;

      // let roleId = this.formInline.roleId;
      if (messageStatus == -1) {
        // console.log("查询全部!", this.formInline);
        this.getMessageB({ currentPage, pageSize, messageStatus });
      } else if (messageStatus || messageStatus == 0) {
        // console.log("状态查询!", this.formInline);

        this.getMessageB({ currentPage, pageSize, messageStatus });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let ids = [];
      if (this.myUserInfo.user_id == 1) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].message_id);
        }
      }
      if (this.myUserInfo.user_id != 1) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i].user_id !== 1) {
            ids.push(this.multipleSelection[i].message_id);
          }
        }
      }
      this.messageIds = ids;
      this.userIds = ids;
      // console.log(this.messageIds);
    },
    handleDelete(index, row) {
      let ids = [];
      ids.push(row.message_id);
      this.messageIds = ids;
      if (this.messageIds.length) {
        this.deleteMessage({ messageIds: this.messageIds }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleRecover(index, row) {
      let ids = [];
      ids.push(row.message_id);
      this.messageIds = ids;
      if (this.messageIds.length) {
        this.passMessage({
          messageIds: this.messageIds,
          messageStatus: 0,
        }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleDeleteAll() {
      if (this.messageIds.length) {
        this.deleteMessage({ messageIds: this.messageIds }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleRecoverAccountAll() {
      if (this.messageIds.length) {
        this.passMessage({
          messageIds: this.messageIds,
          messageStatus: 0,
        }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    // 编辑
    handleEdit(index, row) {
      this.ruleForm2.messageContent = row.message_content;
      this.ruleForm2.messageId = row.message_id;
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.drawer) {
            this.resetForm("ruleForm2");
            this.drawer = false;
          }
          done();
        })
        .catch(() => {});
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.drawerTitle === "编辑") {
            // 调用编辑接口方法
            this.updateMessage({
              messageId: this.ruleForm2.messageId,
              messageContent: this.ruleForm2.messageContent,
            }).then(() => {
              if (this.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.message,
                });
                this.onSubmit();
                this.resetForm(formName);
                this.drawer = false;
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: this.message,
                });
              }
            });
          }
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: "请先填写好表单!",
          });
          return false;
        }
      });
    },
  },
  mounted() {
    this.onSubmit();
    this.$nextTick(function () {
      this.height =
        window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 220;
      // 监听窗口大小变化
      let self = this;
      window.onresize = function () {
        if (self.$refs.multipleTable) {
          self.height =
            window.innerHeight - self.$refs.multipleTable.$el.offsetTop - 220;
        }
      };
    });
  },
};
</script>

<style lang="less" scoped>
@color: #409eff;
.right {
  float: right;
}
.red {
  color: #f56c6c;
}
// 裁剪
.avatar_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.previewBox {
  text-align: center;
  margin-left: 60px;
}

.preview {
  // 裁剪预览图
  width: 500px;
  height: 400px;
  margin: 0px auto 20px auto;
  // border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #ccc;
  overflow: hidden;
}

.cropper {
  // 裁剪盒子
  width: 500px;
  height: 400px;
}

.container {
  .el-tabs {
    /deep/ #tab-first {
      font-weight: 550;
      font-size: 1.25rem;
    }
    // /deep/ .el-tabs__item.is-active {
    //   color: @color;
    // }
    // /deep/ .el-tabs__active-bar {
    //   background: @color;
    //   height: 0.125rem;
    // }
    // /deep/ .el-tabs__nav-wrap::after {
    //   height: 0.125rem;
    // }
    .demo-form-inline {
      /deep/ .el-input__inner {
        width: 14.375rem;
      }
      /deep/ .el-form-item__label {
        padding: 0;
        font-size: 18px;
      }
      // .el-button {
      //   // background: @color;
      //   // font-size: 16px;
      //   // border: 1px solid @color;
      // }
      // .button_left {
      //   .button_reset {
      //     background: #fff;
      //     color: @color;
      //   }
      // }
      .button_right {
        float: right;
        .el-button {
          margin-left: 0.625rem;
        }
        // i {
        //   transform: translateY(2px);
        //   img {
        //     width: 16px;
        //   }
        // }
        // .el-button {
        //   border: 1px solid @color;
        // }
      }
    }

    /deep/ .el-table {
      font-size: 16px;
      .el-button {
        font-size: 16px;
        margin: 0 10px 0 0;
      }
      // .button_public {
      //   // display: none;
      // }
      .is_show {
        display: block;
      }
      .el-table__fixed-right::before,
      .el-table__fixed::before {
        height: 0;
      }
      &::before {
        display: none;
      }
    }
    /deep/ .el-dialog {
      .el-dialog__header {
        &:after {
          width: 95%;
          // height: calc(100% - 0.1rem);
          height: 1px;
          background-image: linear-gradient(
            to right,
            #ccc 0%,
            #ccc 50%,
            transparent 50%
          );
          background-size: 10px 1px;
          background-repeat: repeat-x;
          position: absolute;
          top: 3.5em;
          left: 1em;
          z-index: 1;
          content: "";
        }
      }
      .el-form-item__label {
        font-size: 18px;
      }
    }
    /deep/ .el-drawer__header {
      font-size: 20px;
      color: #333333;
      overflow: hidden;
      &:after {
        width: 93%;
        height: calc(100% - 0.1rem);
        background-image: linear-gradient(
          to right,
          #ccc 0%,
          #ccc 50%,
          transparent 50%
        );
        background-size: 10px 1px;
        background-repeat: repeat-x;
        position: absolute;
        top: 3em;
        content: "";
      }
    }
    /deep/ .el-drawer__body {
      padding: 10px 24px;
      margin-bottom: 20px;
      .el-form-item__label {
        font-size: 18px;
      }
      .zindex_max {
        z-index: 100000;
      }
      // .button_add {
      //   background: @color;
      //   border: @color;
      // }
      // .button_cancel {
      //   border: @color 1px solid;
      //   color: @color;
      // }
      .el-select {
        width: 100%;
      }
    }

    .avatar-uploader-icon {
      color: #8c939d;
      width: 104px;
      height: 104px;
      line-height: 104px;
      text-align: center;
      border: 1px dashed #ccc;
      border-radius: 5px;
      &:hover {
        border-color: @color;
      }
    }
    .avatar {
      width: 104px;
      height: 104px;
      display: block;
      border-radius: 5px;
    }
  }
  .el-pagination {
    float: right;
    position: relative;
    margin: 10px 0;
    padding: 0;
    /deep/ .el-pager .active {
      background-color: @color !important;
    }
  }
}
</style>
